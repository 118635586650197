import React, { useMemo, useState, useEffect, useRef } from "react";
import {
  MaterialReactTable,
  MRT_VisibilityState,
  type MRT_ColumnDef,
  type MRT_PaginationState,
  MRT_SortingState,
} from "material-react-table";
import {
  Eye16Filled,
  MoreHorizontal24Filled,
  Edit16Filled,
  Dismiss24Regular,
} from "@fluentui/react-icons";
import {
  Button,
  makeStyles,
  Tooltip,
  Menu,
  MenuTrigger,
  MenuList,
  MenuItem,
  MenuPopover,
  Avatar,
  Popover,
  PopoverSurface,
  PopoverTrigger,
  Label,
  Dropdown,
  Option,
  useId,
  shorthands,
  Dialog,
  DialogTrigger,
  DialogSurface,
  DialogTitle,
  DialogBody,
  DialogActions,
  DialogContent,
  Toaster,
  useToastController,
  ToastTitle,
  Toast,
  ToastBody,
  ToastFooter,
  ToastTrigger,
  Link,
} from "@fluentui/react-components";
import {
  DrawerBody,
  DrawerHeader,
  DrawerHeaderTitle,
  Drawer,
  DrawerProps,
} from "@fluentui/react-components";
import { ClientDataType } from "../../types";
import CopyToClipBoard from "../../components/CopyToClipBoard";
import { BreadcrumbMenuItem } from "../../types/BreadcrumbOption";
import Search from "../../components/Search";
import Multiselect from "../../components/MultiSelectDropdown";
import DropDownBox from "../../components/DropDownBox";
import "./style.css";
import {
  checkUserPermission,
  convertStringToArray,
  formatedDate,
} from "../../utils";
import apiServices from "../../service";
import { useSelector, useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../redux/store";
import { setDialogModalOptions } from "../../redux/modelDialogReducer";
import { UserPermissionsList } from "../../config";
import ReactQuillComponent from "../../components/ReactQuillComponent";
import OverlayLoader from "../../components/OverlayLoader";
import { debounce } from "lodash";
import { title } from "process";
import { log } from "console";
import SendMail from "./SendMail";
import { Box, IconButton } from "@mui/material";
import {
  setReloadClientList,
  resetReloadClientList,
} from "../../redux/formUpdateReducer";
import { fetchSettings } from "../../redux/formSlice";

type DrawerType = Required<DrawerProps>["type"];

const useStyles = makeStyles({
  wrapper: {
    columnGap: "15px",
    display: "flex",
    cursor: "pointer",
  },
  contentHeader: {
    marginTop: "0",
  },
  scopeArea: {
    display: "grid",
    gridTemplateRows: "repeat(1fr)",
    justifyItems: "start",
    ...shorthands.gap("2px"),
    maxWidth: "400px",
  },
  popup: {
    height: "auto",
    width: "auto",
    position: "absolute",
    backgroundColor: "white",
    ...shorthands.padding("10px"),
    zIndex: 1000,
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.2)",
    alignItems: "center",
    cursor: "pointer",
  },
});

type ClientDataListProps = {
  [x: string]: any;
  changeMode?: (mode: string) => void;
  setActiveItem?: (item: any) => void;
  search: string;
};
const ClientDataList = (props: ClientDataListProps) => {
  const [popupData, setPopupData] = useState<string | null>(null);
  const [popupVisible, setPopupVisible] = useState(false);
  const [popupPosition, setPopupPosition] = useState({ top: 0, left: 0 });
  const toasterId = useId("toaster");
  const { dispatchToast } = useToastController(toasterId);
  const [isOpen, setIsOpen] = useState(false);
  const [isViewOpen, setIsViewOpen] = useState(false);
  const [type, setType] = useState<DrawerType>("overlay");
  const [client, setClient] = useState<ClientDataType[]>([]);
  const [selectClient, setSelectClient] = useState<{
    customer_id: string;
    first_names: string;
    last_name: string;
  }>({ customer_id: "", first_names: "", last_name: "" });
  const [pagination, setPagination] = useState<MRT_PaginationState>({
    pageIndex: 0,
    pageSize: 10,
  });
  const [sorting, setSorting] = useState<MRT_SortingState>([
    {
      id: "NameAndAddress.first_names",
      desc: false,
    },
  ]);
  const [columnVisibility, setColumnVisibility] = useState<MRT_VisibilityState>(
    {
      "NameAndAddress.initials": false,
      "NameAndAddress.former_last_name": false,
    }
  );

  const dispatch: AppDispatch = useDispatch();
  const dialogModalOptions = useSelector((state: RootState) => state.dialog);
  const loggedUser = useSelector((state: RootState) => state.authUser);
  const isReloadClientList = useSelector(
    (state: RootState) => state.formUpdated.isReloadClientList
  );
  const [rowCount, setRowCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const formSettingsObj = useSelector((state: RootState) => state.formSettings);

  useEffect(() => {
    // if (
    //   props.applyFilter === true ||
    //   props.reloadList === true ||
    //   isReloadClientList === true
    // ) {
    //   fetchUsersData();
    // }
    fetchUsersData();
  }, [
    pagination.pageIndex,
    pagination.pageSize,
    props.applyFilter,
    props.reloadList,
    isReloadClientList,
  ]);

  useEffect(() => {
    if (props.setIsLoading !== undefined) {
      props.setIsLoading(loading);
    }
  }, [loading]);
  
  useEffect(() => {
    const debouncedFunction = debounce(() => {
      if (props.search.length >= 2) {
        fetchUsersData();
      } else if (props.search == "") {
        fetchUsersData();
      } else {
      }
    }, 300);

    debouncedFunction(); // Execute the debounced function immediately

    return () => {
      debouncedFunction.cancel(); // Clean up the debounced function
    };
  }, [props.search]);

  const [selectedClient, setSelectedClient] = useState<ClientDataType | null>(
    null
  );
  // console.log(rowCount, "pagination")
  async function fetchUsersData() {

    let scopeSelected =
      props.selectedScope?.length > 0 ? props.selectedScope.join(",") : "";
    setLoading(true);
    apiServices.client
      .getClients(
        pagination.pageIndex + 1,
        pagination.pageSize,
        props.search,
        scopeSelected,
        props.selectedClientType,
        props.searchId
      )
      .then(async (response: any) => {
        if (response) {
          setLoading(false);
          props.setApplyFilter(false);
          await setClient(
            response.data && response.data.data && response.data.data.list
              ? response.data.data.list
              : []
          );
          await setRowCount(
            response.data && response.data.data && response.data.data.count
              ? response.data.data.count
              : 0
          );
          dispatch(resetReloadClientList());
        } else {
          setLoading(false);
          props.setApplyFilter(false);
          dispatch(resetReloadClientList());
          notify("Something went wrong", "", "error");
        }
      })

      .catch((error) => {
        setLoading(false);
        if (error.response && ![401, 403].includes(error.response?.status)) {
          notify("Something went wrong", "", "error");
        }
      });
  }

  function exportApi(customerId: any) {
    apiServices.exportToExcel
      .get(customerId)
      .then(async (response) => {
        const blob = new Blob([response.data]);

        // Create a link element
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "ClientData.xlsx");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const notify = (title?: string, message?: string, type?: any) =>
    dispatchToast(
      <Toast>
        <ToastTitle
          action={
            <ToastTrigger>
              <Link>Dismiss</Link>
            </ToastTrigger>
          }
        >
          {title ?? title}
        </ToastTitle>
        <ToastBody>{message ?? message}</ToastBody>
      </Toast>,
      { intent: type ? type : "success" }
    );

  const styles = useStyles();
  const columns = useMemo<MRT_ColumnDef<ClientDataType>[]>(
    () => [
      {
        accessorKey: "NameAndAddress.first_names",
        header: "First Name",
        sortingFn: (rowA, rowB) => {
          const firstNameA =
            rowA.original.NameAndAddress?.first_names
              ?.toString()
              .toLowerCase() || "";
          const firstNameB =
            rowB.original.NameAndAddress?.first_names
              ?.toString()
              .toLowerCase() || "";
          return firstNameA.localeCompare(firstNameB);
        },
      },

      {
        accessorKey: "NameAndAddress.last_name",
        header: "Last Name",
        sortingFn: (rowA, rowB) => {
          const lastNameA =
            rowA.original.NameAndAddress?.last_name?.toString().toLowerCase() ||
            "";
          const lastNameB =
            rowB.original.NameAndAddress?.last_name?.toString().toLowerCase() ||
            "";
          return lastNameA.localeCompare(lastNameB);
        },
      },
      {
        accessorKey: "NameAndAddress.initials",
        header: "Initials",
      },
      {
        accessorKey: "NameAndAddress.former_last_name",
        header: "Former Last Name",
      },
      {
        accessorKey: "Administration.client_type",
        header: "Client Type",
      },
      {
        accessorKey: "Contact.email", //normal accessorKey
        header: "Email",
      },
      {
        accessorKey: "Contact.mobile",
        header: "Mobile",
      },
      {
        accessorKey: "NameAndAddress.town",
        header: "Town",
      },
      {
        accessorKey: "Personal.date_of_birth",
        header: "Date of Birth",
        Cell: ({ row }: any) => (
          <>{formatedDate(row.original.Personal?.date_of_birth)}</>
        ),
      },
      {
        accessorKey: "Nationality.place_of_birth",
        header: "Place of Birth",
      },
      {
        accessorKey: "Nationality.nationality",
        header: "Nationality",
      },
      {
        accessorKey: "Nationality.country_of_residence",
        header: "Country of Residence",
      },
      {
        accessorKey: "Nationality.country_of_domicile",
        header: "Country of Domicile",
      },
      {
        accessorKey: "Personal.sex",
        header: "Sex",
      },
      {
        accessorKey: "HealthNotes.height",
        header: "Height",
      },
      {
        accessorKey: "HealthNotes.weight",
        header: "Weight",
      },
      {
        accessorKey: "HealthNotes.smoker",
        header: "Smoker",
      },
      {
        accessorKey: "Personal.marital_status",
        header: "Marital Status",
      },
      {
        accessorKey: "Personal.preferred_retirement_age",
        header: "Preferred Retirement Age",
      },
      {
        accessorKey: "Personal.deceased",
        header: "Deceased",
      },
    ],
    []
  );
  const showClient = (row: any) => {
    setSelectedClient(row.original);
    // setIsViewOpen(true);
    if (props.changeMode) {
      props.changeMode("view");
    }
    if (props.setActiveItem) {
      props.setActiveItem(row.original);
    }
    // if (formSettingsObj && formSettingsObj.data && Object.keys(formSettingsObj.data).length === 0) {
    //   dispatch(fetchSettings());
    // }
  };

  const doEditClient = (row: any) => {
    // setIsOpen(true);
    if (props.setActiveItem) {
      props.setActiveItem(row.original);
    }
    if (props.changeMode) {
      props.changeMode("edit");
    }
    // if (formSettingsObj && formSettingsObj.data && Object.keys(formSettingsObj.data).length === 0) {
    //   dispatch(fetchSettings());
    // }
  };
  
  // useEffect(() => {
  //   if (
  //     formSettingsObj &&
  //     formSettingsObj.data &&
  //     Object.keys(formSettingsObj.data).length === 0
  //   ) {
  //     dispatch(fetchSettings());
  //   }
  // }, [formSettingsObj]);

  const clientTypeData: {
    id: number;
    label: string;
  }[] = [
    {
      id: 1,
      label: "Gold",
    },
    {
      id: 2,
      label: "VIP",
    },
  ];
  const scopeData: {
    id: number;
    label: string;
  }[] = [
    {
      id: 1,
      label: "Partner",
    },
    {
      id: 2,
      label: "Client",
    },
  ];

  const handleMouseEnter = (
    event: React.MouseEvent<HTMLTableRowElement>,
    data: string
  ) => {
    const { clientX, clientY } = event;
    if (data) {
      setPopupData(data);
      setPopupPosition({ top: clientY + 10, left: clientX + 10 });
      setPopupVisible(true);
    }
  };

  const handleMouseLeave = () => {
    setPopupVisible(false);
  };
  const [isSendMailDialogOpen, setSendMailDialogOpen] = useState(false);
  const openSendMailDialog = (
    customer_id: string,
    first_names: string,
    last_name: string
  ) => {
    setSendMailDialogOpen(true);
    setSelectClient({ customer_id, first_names, last_name });
  };

  const closeSendMailDialog = () => {
    setSendMailDialogOpen(false);
    props.setReloadList(true);
  };

  return (
    <>
      <Toaster toasterId={toasterId} position="top-end" timeout={5000} />
      <div className="client-list-wrapper">
        <MaterialReactTable
          renderTopToolbarCustomActions={({ table }) =>
            props.searchId && props.searchId !== "" ? (
              !convertStringToArray(
                process.env.REACT_APP_HIDE_CTA_BUTTON
              ).includes("client_extract_btn") ? (
                <Button
                  color="secondary"
                  onClick={() => {
                    alert("Create New Account");
                  }}
                  // variant="contained"
                >
                  Extract
                </Button>
              ) : null
            ) : null
          }
          columns={columns}
          data={client}
          enableColumnFilters={true}
          enableGlobalFilter={false}
          enableDensityToggle={true}
          enableRowSelection={true}
          enableSelectAll={true}
          enableColumnActions={false}
          enableMultiRowSelection={true}
          enableRowActions={true}
          muiTableBodyRowProps={({ row }) => ({
            onMouseEnter: (event) =>
              handleMouseEnter(event, row.original?.Administration?.note_text),
            onMouseLeave: handleMouseLeave,
          })}
          displayColumnDefOptions={{
            "mrt-row-actions": {
              header: "Actions",
              muiTableHeadCellProps: {
                align: "center",
              },
              muiTableBodyCellProps: {
                align: "center",
              },
              enablePinning: false, // Disable pinning for actions column
            },
            "mrt-row-select": {
              header: "Select",
              enablePinning: false, // Disable pinning for select column
            },
          }}
          enableStickyHeader
          enablePinning
          initialState={{
            columnPinning: {
              left: ["mrt-row-select"],
              right: ["mrt-row-actions"],
            },
            columnVisibility,
            sorting: sorting.map((column) => ({
              id: column.id,
              desc: column.desc,
            })),
          }}
          manualPagination
          onSortingChange={setSorting}
          onColumnVisibilityChange={setColumnVisibility}
          onPaginationChange={setPagination}
          rowCount={rowCount}
          state={{ pagination, columnVisibility, sorting }}
          renderRowActions={({ row, table }) => (
            <div className={styles.wrapper}>
              {checkUserPermission(
                loggedUser,
                UserPermissionsList.ALLOW_VIEW_CLIENT
              ) && (
                <Tooltip
                  content="View"
                  relationship="label"
                  positioning="below"
                >
                  <Button
                    appearance="subtle"
                    onClick={() => {
                      showClient(row);
                    }}
                    icon={<Eye16Filled primaryFill="var(--button-color)" />}
                  />
                </Tooltip>
              )}
              {checkUserPermission(
                loggedUser,
                UserPermissionsList.ALLOW_UPDATE_CLIENT
              ) && (
                <Tooltip
                  content="Edit"
                  relationship="label"
                  positioning="below"
                >
                  <Button
                    appearance="subtle"
                    onClick={() => {
                      doEditClient(row);
                    }}
                    icon={<Edit16Filled primaryFill="var(--button-color)" />}
                  />
                </Tooltip>
              )}
              {(checkUserPermission(
                loggedUser,
                UserPermissionsList.ALLOW_CLIENT_EXPORTS
              ) ||
                checkUserPermission(
                  loggedUser,
                  UserPermissionsList.ALLOW_UPDATE_CLIENT
                )) && (
                <Menu>
                  <MenuTrigger disableButtonEnhancement>
                    <Button
                      appearance="subtle"
                      icon={<MoreHorizontal24Filled primaryFill="gray" />}
                    />
                  </MenuTrigger>
                  <MenuPopover>
                    <MenuList>
                      {checkUserPermission(
                        loggedUser,
                        UserPermissionsList.ALLOW_CLIENT_EXPORTS
                      ) && (
                        <MenuItem
                          onClick={() => exportApi(row.original.customer_id)}
                        >
                          Export to Excel
                        </MenuItem>
                      )}
                      <MenuItem
                        disabled={!row.original.Contact.email}
                        onClick={() =>
                          openSendMailDialog(
                            row.original.customer_id,
                            row.original.NameAndAddress.first_names,
                            row.original.NameAndAddress.last_name
                          )
                        }
                      >
                        Send Mail
                      </MenuItem>
                      <MenuItem>Extract</MenuItem>
                    </MenuList>
                  </MenuPopover>
                </Menu>
              )}
            </div>
          )}
          positionActionsColumn="last"
          positionToolbarAlertBanner="bottom"
        />
        {popupVisible && (
          <div
            className={styles.popup}
            style={{ top: popupPosition.top, left: popupPosition.left }}
          >
            <Label weight="semibold">Headline Note</Label>
            <Tooltip
              withArrow
              content="Example tooltip with an arrow"
              relationship="label"
            >
              <ReactQuillComponent value={popupData} />
            </Tooltip>
          </div>
        )}
      </div>
      {isSendMailDialogOpen && (
        <SendMail
          openSendMailDialog={openSendMailDialog}
          isSendMailDialogOpen={isSendMailDialogOpen}
          closeSendMailDialog={closeSendMailDialog}
          selectClient={selectClient}
          reloadList={props.setReloadList}
          notify={notify}
        />
      )}
    </>
  );
};

export default ClientDataList;