import React, { useEffect, useState } from "react";
import FormContent from "./FormContent";
import { useSelector, useDispatch } from "react-redux";
import { RootState, AppDispatch } from "../../redux/store";
import { setFormData, resetFormData } from "../../redux/reducer";
import { setDialogModalOptions } from "../../redux/modelDialogReducer";
import { formBuilder } from "../../utils/formGenerator";
import { useFormik } from "formik";
import moment from "moment";
import * as Yup from "yup";
import {
  makeStyles,
  shorthands,
  Button,
  tokens,
  Label,
  Card,
  CardPreview,
} from "@fluentui/react-components";
import {
  Edit24Filled,
  ArrowUndo16Regular,
  ArrowRedo16Regular,
  Delete16Regular,
  DismissCircle20Regular,
} from "@fluentui/react-icons";
import { checkUserPermission, formatedDate2 } from "../../utils";
import apiServices from "../../service";
import { UserPermissionsList } from "../../config";
import { FormErrorMessage } from "../FormErrorMessage/FormErrorMessage";
import { setReloadClientList } from "../../redux/formUpdateReducer";
import OverlayLoader from "../OverlayLoader";
const useStyles = makeStyles({
  btnwrapper: {
    columnGap: "8px",
    display: "flex",
    justifyContent: "flex-end",
    marginBottom: "6px",
  },
  buttonWrapper: {
    columnGap: "15px",
    display: "flex",
    ...shorthands.padding("10px"),
  },
  containerTopArea: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  message: {
    marginRight: "14px",
  },
});
type FormContainerProps = {
  style?: React.CSSProperties;
  title?: string;
  context: string;
  mode?: string;
  [x: string]: any;
  changeMode?: (mode: string) => void;
  changeTab?: (index: number) => void;
};

const FormContainer = (props: FormContainerProps) => {
  let { context } = props;
  const classes = useStyles();
  // const toasterId = useId("toaster");
  // const { dispatchToast } = useToastController(toasterId);
  const [formContext, setFormContext] = useState("");
  const [nextAction, setNextAction] = useState("");
  const [formMode, setFormMode] = useState(props.mode === "add" ? "New" : "");
  const [formError, setFormError] = useState<any[]>([]);
  const [formFieldData, setFormFieldData] = useState<{ [key: string]: any }>(
    {}
  );
  const [past, setPast] = useState<any[]>([]);
  const [present, setPresent] = useState(null);
  const [future, setFuture] = useState<any[]>([]);
  const [optionList, setOptionList] = useState<any>([]);
  const [selectFieldOptions, setSelectFieldOptions] = useState<any>([]);
  const loggedUser = useSelector((state: RootState) => state.authUser);
  const [selectFieldOptionLabels, setSelectFieldOptionLabels] = useState<any>(
    []
  );
  const [newlyAddedSelectOptions, setNewlyAddedSelectOptions] = useState<any>(
    []
  );
  const [getConsultant, setgetConsultant] = useState("");
  const [getCommissionType, setgetCommissionType] = useState("");
  const [primaryContact, setPrimaryContact] = React.useState("");
  const [primaryContactFields, setPrimaryContactFields] = useState([
    "home_phone",
    "work_phone",
    "mobile",
    "email",
    "work_email",
  ]);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const isFormDataUpdated: boolean = useSelector(
    (state: RootState) => state.someReducer.isFormDataUpdated
  );
  const objFormSettings = useSelector((state: RootState) => state.formSettings);
  const dialogModalOptions = useSelector((state: RootState) => state.dialog);
  const dispatch: AppDispatch = useDispatch();

  const updatePresent = (newState: any) => {
    setPast([...past, present]);
    setPresent(newState);
    setFuture([]);
  };

  const undo = () => {
    if (past.length === 0) {
      setPast([]);
      return;
    }

    const newPast = [...past];
    const newPresent = newPast.pop();

    setPast(newPast);
    setFuture([present, ...future]);
    setPresent(newPresent);

    formik.setValues(newPresent);
    if (JSON.stringify(formik.initialValues) === JSON.stringify(newPresent)) {
      // // props.setFormUpdated(false);
      dispatch(resetFormData());
      setFormMode("");
    } else {
      // // props.setFormUpdated(true);
      dispatch(setFormData());
    }
  };
  const redo = () => {
    if (future.length === 0) return;

    const newFuture = [...future];
    const newPresent = newFuture.shift();

    setPast([...past, present]);
    setFuture(newFuture);
    setPresent(newPresent);
    dispatch(setFormData());
    formik.setValues(newPresent);
  };

  useEffect(() => {
    if (context !== "") {
      setFormContext(context);
      generateDynamicForm(context);
    }
  }, [context]);

  const generateDynamicForm = (formContext: string) => {
    let { initialValues, inputs, validationSchema } = formBuilder({
      context: context,
      formSettings:
        props.module != undefined && props.module == "commission"
          ? objFormSettings.data.commission
          : objFormSettings.data,
    });

    let filteredSelectionOptions = inputs
      .filter(
        (opt: any) =>
          opt.listType !== undefined &&
          [
            "ModifiableList",
            "FixedList",
            "MasterDatawithNewEntry",
            "MasterDatawithoutNewEntry",
          ].includes(opt.listType) &&
          opt.fieldOptionLabel !== undefined
      )
      .map((opt) => ({
        label: opt.fieldOptionLabel,
        listType: opt.listType,
        formField: opt.field,
      }));
    setSelectFieldOptionLabels(filteredSelectionOptions);
      getSelectionListOptions(filteredSelectionOptions);
    setFormFieldData({ initialValues, inputs, validationSchema });
   
  };
  useEffect(()=>{
    if (props.initialData.primary_contact !== undefined) {
      setPrimaryContact(props.initialData.primary_contact);
    }
  },[props.initialData])
 
  const getSelectionListOptions = async (fieldLabels: any) => {
    try {
      let fieldLbl = "";
      if (fieldLabels.length > 0) {
        fieldLabels.map((itm: any, index: number) => {
          if (fieldLabels.length - 1 != index) {
            fieldLbl = fieldLbl.concat(`${itm.label}:${itm.listType},`);
          } else {
            fieldLbl = fieldLbl.concat(`${itm.label}:${itm.listType}`);
          }
        });

        apiServices.selectionList
          .getOptions(fieldLbl)
          .then((res) => {
            if (res.data && res.data.data) {
              setSelectFieldOptions(res.data.data);
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const formik = useFormik({
    initialValues:
      props.mode && ["edit", "view"].includes(props.mode)
        ? props.initialData
        : formFieldData.initialValues
        ? formFieldData.initialValues
        : {},
    validationSchema: formFieldData ? formFieldData.validationSchema : null,
    enableReinitialize: true,
    onSubmit: function (values, { setSubmitting }) {
      if (Object.keys(formik.errors).length === 0) {
        formSubmitted(values);
      }
    },
  });
  useEffect(() => {
    setPresent(formik.initialValues);
  }, [formik.initialValues]);
  useEffect(() => {
    if (props.clientFormSubmited === true && formik) {
      formik.handleSubmit();
    }
  }, [props.clientFormSubmited]);
  const updateConsultantName = (name: any) => {
    setgetConsultant(name);
  };
  const updateCommissionType = (name: any) => {
    setgetCommissionType(name);
  };

  const formSubmitted = (values: any) => {
        // setIsFormLoading(true);
    const payload = formFieldData.inputs.map(({ field }: any) => field);

    let reqBody: { [key: string]: any } = {};


    if (payload && payload.length > 0) {
      payload.map((el: any) => {
        if (values.hasOwnProperty(el)) {
          reqBody[el] = values[el] !== null ? values[el] : "";
        }
      });

      if (reqBody.consultant !== undefined) {
        setgetConsultant(reqBody.consultant);
      }
      if (reqBody.commission_type !== undefined) {
        setgetCommissionType(reqBody.commission_type);
      }
    }

    if (props.mode === "add") {
      setFormError([]);
      let addReq: any = {};
      if (
        props.config &&
        props.config.renderForm &&
        props.config.renderForm.reference_id
      ) {
        addReq[props.config.renderForm.reference_id] = reqBody;
        addReq[props.config.renderForm.reference_id]["client_value"] = "client";
      }
      if (props.module != undefined && props.module == "commission") {
        apiServices.commissionModule.commissionClient
          .add(addReq)
          .then((response: any) => {
            if (!response.data.isError) {
              AddSelectOptions();
              setTimeout(() => {
                setIsFormLoading(false);
                props.notify(response.data.message, "", "success");
              }, 1000);
              dispatch(resetFormData());
            }
          })
          .catch((e: Error) => {
            console.log("err");
          });
      } else {
        apiServices.client
          .createNew(addReq)
          .then((response: any) => {
            if (!response.data.isError) {
              AddSelectOptions();
              setTimeout(() => {
                setIsFormLoading(false);
                props.notify(response.data.message, "", "success");
              }, 1000);
              dispatch(resetFormData());
              props.getList();

              if (props.getClientDetail) {
                props.getClientDetail(response.data.data?.customer_id);
              }
              if (props.resetClientFormSubmited) {
                props.resetClientFormSubmited();
              }
              if (props.changeMode) {
                props.changeMode("edit");
              }
              if (nextAction === "next") {
                props.changeTab?.(props.tabMenuIndex.next);
              }
            } else if (response.data.isError) {
              setIsFormLoading(false);
              if (
                response.data.error &&
                response.data.error &&
                Object.keys(response.data.error).length !== 0
              ) {
                setFormError(response.data.error.message);
              }
            } else {
              setIsFormLoading(false);
              props.notify("Something went wrong", "", "error");
            }
          })
          .catch((e: Error) => {
            setIsFormLoading(false);
            props.notify(e.message, "", "error");
          });
      }
    } else if (props.mode === "edit") {
      setFormError([]);
      let updateReq: any = {};
      if (
        props.config &&
        props.config.renderForm &&
        props.config.renderForm.reference_id
      ) {
        if (props.module != undefined && props.module == "commission") {
          updateReq[props.config.renderForm.reference_id] = reqBody;
        } else {
          updateReq[props.config.renderForm.reference_id] = reqBody;
          if (props.config.renderForm.reference_id === "contact") {
            updateReq[props.config.renderForm.reference_id]["primary_contact"] =
              primaryContact;
          } else if (
            props.config.renderForm.reference_id === "administration"
          ) {
            updateReq[props.config.renderForm.reference_id] = {
              ...reqBody,
              client_since: formatedDate2(reqBody.client_since),
              review_completed: formatedDate2(reqBody.review_completed),
              review_date: formatedDate2(reqBody.review_date),
            };
            updateReq[props.config.renderForm.reference_id]["consultant"] =
              getConsultant === "" ? getConsultant : getConsultant;
            if (
              !checkUserPermission(
                loggedUser,
                UserPermissionsList.ALLOW_RESTRICTED_CLIENT
              )
            ) {
              delete updateReq["administration"]["pending"];
            }
          } else if (props.config.renderForm.reference_id === "personal") {
            updateReq[props.config.renderForm.reference_id] = {
              ...reqBody,
              date_of_birth: formatedDate2(reqBody.date_of_birth),
              state_retirement_date: formatedDate2(
                reqBody.state_retirement_date
              ),
              date_of_marriage: formatedDate2(reqBody.date_of_marriage),
              will_date: formatedDate2(reqBody.will_date),
              death_date: formatedDate2(reqBody.death_date),
            };
          }
        }
      }
      if (props.module != undefined && props.module == "commission") {
        apiServices.commissionModule.commissionClient
          .update(props.activeCustomerId, updateReq)
          .then((response: any) => {
            if (!response.data.isError) {
              AddSelectOptions();
              setTimeout(() => {
                setIsFormLoading(false);
                props.notify(response.data.message, "", "success");
              }, 1000);
              dispatch(resetFormData());
            }
          })
          .catch((e: Error) => {
            console.log("err");
          });
      } else {
        apiServices.client
          .updateClient(props.activeCustomerId, updateReq)
          .then((response: any) => {
            if (!response.data.isError) {
              AddSelectOptions();
              setTimeout(() => {
                setIsFormLoading(false);
                props.notify(response.data.message, "", "success");
              }, 1000);
              dispatch(resetFormData());
              // props.getList();
              dispatch(setReloadClientList());
              if (props.getClientDetail) {
                props.getClientDetail(props.activeCustomerId);
              }
              generateDynamicForm(context);
              if (props.resetClientFormSubmited) {
                props.resetClientFormSubmited();
              }
              if (props.changeMode) {
                props.changeMode("edit");
              }
              if (nextAction === "next") {
                props.changeTab?.(props.tabMenuIndex.next);
              }
            } else if (response.data.isError) {
              setIsFormLoading(false);
              if (
                response.data.error &&
                response.data.error &&
                Object.keys(response.data.error).length !== 0
              ) {
                setFormError(response.data.error.message);
              }
            } else {
              props.notify("Something went wrong", "", "error");
            }
          })
          .catch((e: Error) => {
            setIsFormLoading(false);
            props.notify(e.message, "", "error");
            setIsFormLoading(false);
          });
      }
    }
  };

  const customHandleChange = (event: any) => {
    handleOnChange(event);
  };
  const handleOnChange = (event: any) => {
    const data = { ...formik.values };
    data[event.target.name] = event.target.value;
    let updated = false;

    updatePresent(data);

    if (JSON.stringify(formik.initialValues) === JSON.stringify(data)) {
      dispatch(resetFormData());
      updated = false;
    } else {
      dispatch(setFormData());
      updated = true;
    }

    if (props.mode !== "add" && updated === true) {
      setFormMode("Changed");
    } else {
      setFormMode(props.mode === "add" ? "New" : "");
    }
  };
  const setFieldAsPrimary = (field: string) => {
    setPrimaryContact(field);
  };
  const newSelectOptionAdded = (
    field: string,
    options: any,
    operation: string
  ) => {
    let labelInfo = selectFieldOptionLabels.find(
      (e: any) => e.formField === field
    );
    if (operation === "remove") {
      const filteredRes = newlyAddedSelectOptions.filter(
        (item: any) => item.field !== field
      );
      setNewlyAddedSelectOptions(filteredRes);
    } else {
      if (labelInfo) {
        let newVall: any = {
          type: labelInfo.listType,
          options: [],
        };
        newVall["options"].push({
          field: labelInfo.label,
          label: options.label,
          value: options.value,
        });

        setNewlyAddedSelectOptions((prev: any) => [...prev, newVall]);
      }
    }
  };

  const AddSelectOptions = () => {
    if (newlyAddedSelectOptions.length > 0) {
      apiServices.selectionList
        .addOptions(newlyAddedSelectOptions)
        .then((response: any) => {})
        .catch((err) => console.log(err));
    }
  };
  return (
    <>
    <OverlayLoader isLoading={isFormLoading} />
      {props.mode && ["edit", "add"].includes(props.mode) && (
        <div className={classes.btnwrapper}>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Label size="medium" weight="semibold" className={classes.message}>
              {formMode !== "" && (
                <>
                  <span>**</span> {formMode} <span>**</span>
                </>
              )}
            </Label>
          </div>

          <div>
            <Button
              icon={<ArrowUndo16Regular />}
              onClick={undo}
              disabled={!isFormDataUpdated}
            ></Button>
            <Button
              icon={<ArrowRedo16Regular />}
              onClick={redo}
              disabled={future.length === 0 ? true : false}
            ></Button>
          </div>
          <Button
            appearance="transparent"
            onClick={() => {
              formik.resetForm();
              dispatch(resetFormData());
            }}
            disabled={!isFormDataUpdated}
          >
            Reset All
          </Button>
          {context === "ADMINISTRATION" && (
            <>
              {checkUserPermission(
                loggedUser,
                UserPermissionsList.ALLOW_DELETE_CLIENT
              ) && (
                <Button
                  icon={<Delete16Regular />}
                  style={{ marginRight: 2, marginBottom: 6, color: "red" }}
                  size="small"
                  onClick={() => {
                    dispatch(
                      setDialogModalOptions({
                        open: true,
                        content_line_1: "Do you want to delete?",
                        no: {
                          onclick: () => {
                            dispatch(setDialogModalOptions({ open: false }));
                          },
                          label: "No",
                          type: "primary",
                        },
                        cancel: {
                          onclick: () => {
                            dispatch(setDialogModalOptions({ open: false }));
                          },
                          label: "Yes",
                        },
                      })
                    );
                  }}
                >
                  Delete
                </Button>
              )}
            </>
          )}
          {context === "CLIENT_ADDRESS" && (
            <Button appearance="secondary" shape="square" onClick={() => {}}>
              Set as Client Address
            </Button>
          )}
        </div>
      )}
      {props.mode && props.mode === "view" && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            zIndex: 1,
            background: "#fff",
            paddingRight: "5px",
          }}
        >
          {checkUserPermission(
            loggedUser,
            UserPermissionsList.ALLOW_UPDATE_CLIENT
          ) && (
            <Button
              style={{ marginRight: 2, marginBottom: 6 }}
              icon={<Edit24Filled />}
              onClick={() => {
                if (props.changeMode) {
                  props.changeMode("edit");
                }
              }}
            >
              Edit
            </Button>
          )}
        </div>
      )}
      {formError && formError.length > 0 && (
        <FormErrorMessage
          errorMessages={formError}
          onDismiss={() => setFormError([])}
        />
      )}
      <div style={{ height: "calc(100vh - 380px)", overflow: "auto" }}>
        {["CLIENT_CONTACT"].includes(context) ? (
          <FormContent
            {...{
              formik,
              formFieldData,
              mode: props.mode,
              handleOnChange,
              primaryContact,
              setFieldAsPrimary,
              primaryContactFields,
              context,
              optionList,
              customHandleChange,
              selectFieldOptions,
              newSelectOptionAdded,
            }}
          />
        ) : (
          <FormContent
            {...{
              formik,
              formFieldData,
              mode: props.mode,
              handleOnChange,
              context,
              optionList,
              customHandleChange,
              selectFieldOptions,
              newSelectOptionAdded,
              updateConsultantName,
              updateCommissionType,
            }}
          />
        )}

        {props?.mode !== "view" && (
          <div className={classes.buttonWrapper}>
            <Button
              appearance="primary"
              className="asc-button-primary"
              onClick={() => (isFormDataUpdated ? formik.handleSubmit() : "")}
              disabled={isFormLoading || !isFormDataUpdated}
            >
              Save
            </Button>

            {props.tabMenuIndex && props.tabMenuIndex.next !== -1 && (
              <Button
                onClick={() => {
                  setNextAction("next");
                  isFormDataUpdated
                    ? formik.handleSubmit()
                    : setNextAction("next");
                }}
                disabled={isFormLoading || !isFormDataUpdated}
              >
                Save & Next
              </Button>
            )}

            <Button
              onClick={() => {
                if (isFormDataUpdated) {
                  dispatch(
                    setDialogModalOptions({
                      open: true,
                      content_line_1:
                        "We have detected an attempt to move away from the current page.",
                      content_line_2:
                        "This would cause all changes made to be lost",
                      cancel: {
                        onclick: () => {
                          dispatch(
                            setDialogModalOptions({
                              open: false,
                            })
                          );
                          dispatch(resetFormData());
                          props.closePage();
                        },
                        label: "Leave the Page",
                      },
                      no: {
                        onclick: () => {
                          dispatch(setDialogModalOptions({ open: false }));
                        },
                        label: "Stay on Page",
                        type: "primary",
                      },
                    })
                  );
                } else {
                  props.closePage();
                }
              }}
            >
              Cancel
            </Button>
          </div>
        )}
      </div>
    </>
  );
};

export default FormContainer;
